import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '1Cf8FUf5I6z7uhRlUVTqhd',
      type: 'Carousel',
      props: {
        technicalName: '[UK][HP] Top slider (B = home-duplicated)',
        slides: [
          {
            id: '4Ishun2D0w7GmWisl3VkSR',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5W8ETvj782qHgHuWVNiqL3/a323fa15668579d59baf9cbc3331ed32/DN_HP-Banners_2_desktop_US.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6U4Me9b9kmwGHN5TmTirfx/313b524c8db5e55d094185cad17b17f1/DN_HP-Banners_2_mobile_US.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'UK - iPhones x Downgrade Now',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: '5o9qsfEp2HC7hW75PLtbWW',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3HraOccRKHak0eovGu79KU/025ef94f7f3dda6962fa29f78e916c85/GENERIC_HPBANNERS_MacBookAirM1_Desktop_UK.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6r45VgNcYxzue4fYUeSNKP/8c2fdd0b92858bc62e31ce1cb7509f94/GENERIC_HPBANNERS_MacBookAirM1_Mobile_UK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'UK - MacBook x Generic',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/l/macbook-m1-serie/28f79b52-b5c8-4b2b-8325-e61d404808f0',
            },
          },
          {
            id: '3fddHIZnAtAsM81dXCjOzQ',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/35gPg9vwcMHQdyARV1tcUR/ea23eae4707ca9769b57b3ccf7796974/GENERIC_HPBANNERS_iPad2021_Desktop_UK.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1u3PXx7NYMsvI2Jcd8Ek01/76803450a5bbf084c5af90bedd28422d/GENERIC_HPBANNERS_iPad2021_Mobile_UK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'UK - iPad x Generic',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/l/ipad/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
            },
          },
          {
            id: '6W8rMa3f45JjW7CKPNttmg',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5Mw25tkPaAkQ62wiEsRElX/48d6b0f72d54dfae9ff32b8e063f6647/GENERIC_HPBANNERS_Watch8_Desktop_UK.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/54ntiVPlzv8ByxhjXK2PNG/8f61ee4356bad20cc4f13bb8ae424e96/GENERIC_HPBANNERS_Watch8_Mobile_UK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'UK - Apple Watch x Generic',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/l/apple-watch-series-8/076b7677-f460-40f7-821c-c24b8a3da621',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: 'NDvB1mz9VBBE1Xt7aevKM',
      type: 'SectionBanner',
      props: {
        technicalName: '[UK][HP] Value prop',
        title: 'Tech better with us',
        description: 'Buy and sell tech that’s better for the planet.',
      },
      hiddenDevices: [],
    },
    {
      id: '5OCmB0IibS7lokXhZRmQAO',
      type: 'Reinsurance',
      props: {
        technicalName: '[UK][HP] Value prop items',
        blocks: [
          {
            id: '16v4m2VZgzvlz6nBhSwlki',
            title: 'Professionally refurbished',
            icon: 'IconOfficial',
            link: {
              href: 'https://backmarket.co.uk/en-gb/quality',
            },
            linkPosition: 'title',
          },
          {
            id: '3xDGvz7OMk350lBvHKBcmX',
            title: 'Cashback with Trade-in',
            icon: 'IconPriceUpdate',
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/buyback/home',
            },
            linkPosition: 'title',
          },
          {
            id: '1K6HSQkKekgVOi5aWmaS5M',
            title: 'Pay-as-you-go',
            icon: 'IconCreditCard',
            link: {
              href: 'https://help.backmarket.com/hc/en-gb/articles/360026643854-What-payment-methods-are-accepted-on-Back-Market',
            },
            linkPosition: 'title',
          },
          {
            id: 'bq860yp2UyHLcvr6RHFzt',
            title: 'App exclusive features',
            icon: 'IconSmartphone',
            link: {
              href: 'https://backmarket.onelink.me/j4lA/1senir84',
            },
            linkPosition: 'title',
          },
        ],
        mobileScreenCols: 2.0,
        hasBackground: true,
        theme: 'white',
      },
      hiddenDevices: [],
    },
    {
      id: '730erCEEiampH4KnyjqwME',
      type: 'ModularCardsCarousel',
      props: {
        technicalName: '[UK][HP] Most wanted categories',
        title: 'Shop our most wanted',
        size: 'small',
        cards: [
          {
            id: '5HIx9VCpwJl1b2afAWlUbM',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '5eh6AvozrsesUHTZK86bV2',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[UK][DN] iPhone',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5QiFf3IKAPnyvmJAbKAkL4/dcfe48dcda407e58543a27fbc455cc81/Phone_HP_Category_Smartphone_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/5pOFam9DGZq71N9Mjp6oq8/0e863c5d0cec1a46c7f13b603c86b994/Phone_HP_Category_Smartphone_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'iPhones',
                      },
                      link: {
                        href: 'https://www.backmarket.co.uk/en-gb/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '58P4W22gYotEfK2yBOraGU',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'iPhone',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'iPhone',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '79A9fuvsllvDgdpJriipxp',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '6pGjAy0ySavmVwMkMxBaX',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[UK][DN] Macbook',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5GjoGJCqWVngJRShjjG7a/b7d6c965d1a18230836a0c1a904364b4/HP_Category_Laptop_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/4W39yhNXfMespBqgSIXmLQ/de3452984a124cfc67f00646925d0556/HP_Category_Laptop_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'Macbook',
                      },
                      link: {
                        href: 'https://www.backmarket.co.uk/en-gb/l/macbook/297f69c7-b41c-40dd-aa9b-93ab067eb691',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: 'ITga4rtB8DlRWJT8nHtDk',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'Macbook',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'Macbook',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '4qoGp9V60gEhg1Ra3kaBap',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '52i4TUCQvfXQFWFSTJt8s8',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[UK][DN] iPad',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/4hFoTgvuPtlS2xGUdtLsRy/9f5d0963c59dc1faa5dc0e3d814ceb50/HP_Category_Tablet_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/2AmON8mdD9dHD2bp0521LV/41c35408ce638eac131abcad0ddf5592/HP_Category_Tablet_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'iPad',
                      },
                      link: {
                        href: 'https://www.backmarket.co.uk/en-gb/l/ipad/6053d9e8-2eaa-4971-9b6e-79b8a16e4dee',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: '1P2K4SEOLPbvI5LUusK6UB',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'iPad',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'iPad',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
          {
            id: '5XkmfpufJgRyB5JLrkHUhl',
            sections: [
              {
                alignment: 'left',
                slots: [
                  {
                    id: '2pQc2FBLEmYKYg6KTkuWHT',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[UK][DN] Console',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/5Q6Fw32r8NELQcVLLxxqim/8c3e6c363ff0ce4a95eec65fa0168500/Phone_HP_Category_Gaming_desktop.jpg',
                        width: 536,
                        height: 360,
                        srcMobile:
                          'https://images.ctfassets.net/mmeshd7gafk1/7opuBWTtcbitW7A2tcaLHx/64b6c3cd8aed53835f522c0d56921559/Phone_HP_Category_Gaming_mobile.jpg',
                        widthMobile: 400,
                        heightMobile: 400,
                        alt: 'Gaming console',
                      },
                      link: {
                        href: 'https://www.backmarket.co.uk/en-gb/l/game-console/2abcb67f-ffa2-4995-b473-167490d42389',
                      },
                      rounded: true,
                    },
                  },
                  {
                    id: 'vjIoPeihH1dVUhlAZyKNn',
                    type: 'RichText',
                    hiddenDevices: [],
                    props: {
                      technicalName: 'Consoles',
                      richText: {
                        data: {},
                        content: [
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: 'Gaming console',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.HEADING_3,
                          },
                          {
                            data: {},
                            content: [
                              {
                                data: {},
                                marks: [],
                                value: '',
                                nodeType: 'text',
                              },
                            ],
                            nodeType: BLOCKS.PARAGRAPH,
                          },
                        ],
                        nodeType: BLOCKS.DOCUMENT,
                      },
                    },
                  },
                ],
              },
            ],
            background: null,
          },
        ],
        cardsHavePadding: false,
        layout: 'grid',
        mobileScreenColumns: 2,
      },
      hiddenDevices: [],
    },
    {
      id: '9vnBMG14XswTR87dN1PPL',
      type: 'Carousel',
      props: {
        technicalName: '[UK][HP] Content Slider',
        slides: [
          {
            id: '2tQHTQ7ZInKh1nAfGcA4dj',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2T9ErDOpAaJ1Hi58z9pzSn/ab049ccf2578fead7de363797a275e63/Slider-3-desktop-UK-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5KVuOyciLi1EKK21i1dtVq/d546067d8c4bb82a49d4285562ea55be/Slider-3-mobile-UK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'What is Verified Refurbished',
            },
            link: {
              href: 'https://backmarket.co.uk/en-gb/quality',
            },
          },
          {
            id: '6v9tNKQISp7bC7EyV5VYGm',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4gSQe7IF303sjsH71XsWM3/1e89aacf314923f9b339c911c45abeee/Slider-1-desktop-UK-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3cvd6zNtIUXNXwW5gb0xqs/f3e5fd774f4a305eaba214d7cad20b01/Slider-1-mobile-UK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'Refurbished, better than used',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/c/news/used-vs-refurbished',
            },
          },
          {
            id: 'g4CjBf2NspGQE0sLPwgis',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5XoWhUKhH4w8CoeR6VrKif/74fafe77cfe3de1aa8852a01438a1fe2/Slider-2-desktop-UK-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3SmRsumTmHSbe9QFD75EZL/38d9b55df713a44ee9a0ea671b569a13/Slider-2-mobile.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'Back Market is better than new',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/c/news/used-vs-refurbished-smartphone',
            },
          },
          {
            id: '7eyZUJTzWtzFUgbbnOGfUm',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/75ngmt4iPow0sUYPUCdP7b/abc1604a6c2bbd47f27051b34882d501/Slider-4-desktop-UK-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4iilzbyxiYc1sXWAvg1BwT/fd2bd1c152343c571b71d06ee0c8b941/Slider-4-mobile-UK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: "We've prevented over 1 milion tons of CO2 emissions",
            },
            link: {
              href: 'https://backmarket.co.uk/en-gb/impact/en-gb/impact-report-2024',
            },
          },
          {
            id: '53hsCoaMBTQGjJXtcYKl4f',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5YDv2y0xq8QaRIp3v6iXpD/c8696c4668b7cf5cd32327f198c6a519/Slider-5-desktop-UK-x2.jpg',
              width: 2240,
              height: 800,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4rQLaohmk9wFHk6ZfdAU9q/2d5cf3e99c8526fbb0d386005035d11c/Slider-5-mobile-UK.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: '',
            },
          },
        ],
        isFullWidth: false,
      },
      hiddenDevices: [],
    },
    {
      id: '2p3kGiXIpSuenh715tsd7Y',
      type: 'SectionBanner',
      props: {
        technicalName: '[UK][HP] Sustainability value prop',
        title: 'Refurbished tech helps the planet.',
        description:
          'We believe in a world that does more with what we already have.',
        button: {
          technicalName: '[UK] Impact report button',
          variant: 'primary',
          size: 'normal',
          ctaLink: {
            label: 'See why',
            link: {
              href: 'https://backmarket.co.uk/en-gb/impact/en-gb/impact-report-2024',
            },
          },
        },
      },
      hiddenDevices: [],
    },
    {
      id: '6coMGFJHQNyLJaG4zmk0v',
      type: 'Media',
      props: {
        technicalName: '[UK][HP] Trade-in',
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/3DDzO9xDvFZ1GVyoHjpNwz/bfb3e357cb166e5054853466175bc319/Desktop___UK.png',
          width: 2244,
          height: 648,
          srcMobile:
            'https://images.ctfassets.net/mmeshd7gafk1/4JfaBEO8f6W2oboc4ShUBc/a5cb72abbec0a36750c32f557384ca3e/Mweb___UK.png',
          widthMobile: 780,
          heightMobile: 872,
          alt: 'trade-in',
        },
        link: {
          href: 'https://www.backmarket.co.uk/en-gb/buyback/home',
        },
      },
      hiddenDevices: [],
    },
    {
      id: '2ZjoEbpAVGE6boP9y5N6SO',
      type: 'ArticleCardsList',
      props: {
        technicalName: '[UK][HP] Sustainability Articles',
        title: 'Refurbished tech helps the planet',
        subtitle: '',
        articles: [
          {
            id: 'RxKOErzL2V27QnBrUjeCj',
            text: 'Does Back Market really prevent environmental damage? It definitely does, all thanks to purchases made by you.',
            category: 'news',
            translatedCategory: 'News',
            title: 'Back Market has prevented 1 million tonnes of carbon.',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/c160e233e1f64d4630bf41f765ea305f-meta-526-276/9cee0099cea7d02d9eec454ef63cf739/UK-Blog_Art._Header_V1.png',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Back Market has prevented 1 million tonnes of carbon.',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/c/news/one-million',
            },
          },
          {
            id: '1k2AiuWMTV81DbexolawSd',
            text: 'Buying refurbished tech and electronics can impact more than just your savings. We now have scientific proof that refurbished electronics help the planet.',
            category: 'news',
            translatedCategory: 'News',
            title: 'What does refurbished mean for the environment?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6739520177b4fd52b64f0afa2bd4f452-meta-526-276/b48df90a34632b4651578e0a0f4007ec/The_environmental_impact_of_refurbished_tech.png',
              width: 526,
              height: 276,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'What does refurbished mean for the environment?',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/c/news/impact-of-refurbished-on-environment',
            },
          },
          {
            id: 'GYT6O4ogoYvy2tdv4bC8m',
            text: "Being able to repair your tech isn't as easy as it should be. But the law is changing. Find out how extending your device's lifespan is key to tackling climate change.",
            category: 'tech-education',
            translatedCategory: 'Tech Education Articles',
            title: 'Why we need a right to repair movement',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3oXkiU6JsTVR2yZdgTSPvE/cbeb99271d744b496f05bc8abe6d88e8/SEO_ARTICLE_IMAGE_516x328.jpg',
              width: 516,
              height: 328,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Why we need a right to repair movement',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/c/tech-education/what-is-right-to-repair',
            },
          },
          {
            id: '4XIEwc9Q0pXCu5EZRQscM7',
            text: 'Our trade in program is an easy, contract-free way to make cash, help the planet, and clear out your old tech.',
            category: 'news',
            translatedCategory: 'News',
            title: 'How Trade-in helps the planet — and your wallet',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2ri9Poy9s7fJN7KW7dRKbG/0fd4230b15905f6cf4890dc7e50603de/Thumbnail.jpg',
              width: 480,
              height: 265,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'How Trade-in helps the planet — and your wallet',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/c/news/trade-in-planet',
            },
          },
          {
            id: '2IBZEJwDPIcfhadhKW3tQt',
            text: 'Back Market has received B Corp status. What does B Corp status mean, and what does it mean for Back Market?',
            category: 'news',
            translatedCategory: 'News',
            title: 'Back Market gets B Corp status',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/fee2a185574550d04544923d2177a379-meta-526-276/12dce68f7785aeae56ae4e2ed8f80822/BCorp_Banner_meta.png',
              width: 516,
              height: 328,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Back Market gets B Corp status',
            },
            link: {
              href: 'https://www.backmarket.co.uk/en-gb/c/news/bcorp-status',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: 'yM0SzfINkF2nyF2CYU58T',
      type: 'Buttons',
      props: {
        technicalName: '[UK][HP] BackStories button',
        primary: {
          link: {
            href: 'https://www.backmarket.co.uk/en-gb/back-stories/home',
          },
          label: 'Read more',
        },
      },
      hiddenDevices: [],
    },
    {
      id: '62JLv45CijzDPdjL2rXn8X',
      type: 'Faq',
      props: {
        technicalName: '[UK][HP] FAQ about services',
        title: '3 questions people always ask',
        subtitle: '',
        questions: [
          {
            id: '5va6W67T03CVdS8BjELxFN',
            title: 'Can you help me recycle my old phone or other electronics?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'We can do you one better — give you cash with ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.co.uk/en-gb/buyback/home',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'Trade-In',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        '. You can get an offer in minutes from a refurbisher to trade in your old device. To make it extra juicy, we also cover the cost of shipping.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6P6batXqRdRTnpaIztmncr',
            title: 'Can I pay for my device over time?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Yep. A huge benefit of refurbished tech is that you can get the same functionality for up to 70% less than new — and to make that price point even more accessible, we offer several different ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://help.backmarket.com/hc/en-gb/articles/360026643854-What-payment-methods-are-accepted-on-Back-Market',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'pay-as-you-go options',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '. ',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6CnGJt0l0YWxU9luBHsmda',
            title: 'Can I buy a protection plan for my device?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'We’ve got you covered for life’s not-so-happy accidents. Our protection plans cover drops, spills, and more. We call it ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.co.uk/en-gb/e/backup',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'BackUp',
                          marks: [
                            {
                              type: 'bold',
                            },
                          ],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        ', and you’ll see the option to purchase it alongside your device when you’re checking out. After all, the longer you’re able to use your device, the better it is for the planet. ',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'JSON_AND_HTML',
      },
      hiddenDevices: [],
    },
    {
      id: '4VuKfOcVfEo9Pa9FD4o69p',
      type: 'ModularCardsCarousel',
      props: {
        technicalName: '[UK][HP] Press carousel',
        title: 'As seen in',
        size: 'small',
        cards: [
          {
            id: '1AJjIsThZuSagmIYPaWHW9',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '1zSSE0Mistpvv47fDbYC2S',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[UK] The Guardian',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/6E14pQfK0smYS02bFSxb77/af53a4b1b34fc119dace97fbcd58cf9e/guardian.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Logo The Guardian',
                      },
                      link: {
                        href: 'https://www.theguardian.com/business/article/2024/jun/11/it-should-be-a-right-to-fix-your-phone-the-boss-of-booming-secondhand-tech-firm-back-market',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '7pSiFnu6OdckeewRCgWCzK',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '2iB7VWKAPDT4EZ6vxjTWXa',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[UK] Daily Mail',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/nM62QRYfLWfXrcSRKLJed/88db75ba5bbf1ff17dde88c07a890d32/daily_mail.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Daily mail',
                      },
                      link: {
                        href: 'https://www.dailymail.co.uk/sciencetech/article-11998881/Im-cellphone-repair-expert-heres-big-charging-mistake-makes.html',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '73sbHzbXwYs6eBfx3NIUGh',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '3efbMoLvXrWTjI9PBu7jcg',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[UK] The Times',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/3Eprh6BPaFqNizGwOioAeA/97ebab77665cb94a53c78f918d0478ed/the_times.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Logo The times',
                      },
                      link: {
                        href: 'https://www.thetimes.co.uk/article/laptop-and-gadget-recycler-to-open-uk-market-bkm0vxjsx',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: '4CAWesw6Fjs4V09CuyugiB',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '5iYNGdqtjlqONdeAcJptqX',
                    type: 'ModularCardImage',
                    hiddenDevices: [],
                    props: {
                      technicalName: '[UK] Yahoo News',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/6bsrsxoAsuuYP6cYwdRgQx/1a6ff0d2ee755fcf8900f402627d2a41/Yahoo.jpg',
                        width: 256,
                        height: 136,
                        srcMobile: '',
                        widthMobile: 0,
                        heightMobile: 0,
                        alt: 'Logo Yahoo News',
                      },
                      link: {
                        href: 'https://uk.news.yahoo.com/how-to-speed-up-an-ageing-laptop-according-to-a-pc-repair-expert-132703709.html?guccounter=1',
                      },
                      rounded: false,
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
        ],
        cardsHavePadding: true,
        layout: 'carousel',
        mobileScreenColumns: 1,
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: "Back Market: Refurbished tech that's better for the planet",
  meta: {
    title: "Back Market: Refurbished tech that's better for the planet",
    description:
      'Buy the best refurbished smartphones, laptops and tech on Back Market, at up to 70% cheaper than buying new! Free delivery - 12 month warranty - secure payments - 30 day returns.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/ba7da39a8ebadca65d09766ea8c3f04d/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: "Refurbished tech that's better for the planet",
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
